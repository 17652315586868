<!--
 * @Author: your name
 * @Date: 2020-09-18 11:46:48
 * @LastEditTime: 2021-06-23 18:34:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT_Web_test\src\views\leadingCockpit\components\accountsReceivableDrill\enterDrill.vue
-->
<template>
    <div class="enterDrill">
        <h4>
            <p>{{ title }}</p>
            <div class="light-beam"></div>
        </h4>
        <div class="wrapper">
            <!-- 表格 -->
            <table class="table">
                <thead>
                    <tr>
                        <th></th>
                        <th class="tit" v-for="(item, index) in names" :key="`name_${index}`">
                            {{ item }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="tit">
                            累计应收
                        </td>
                        <td
                            class="num"
                            v-for="(item, index) in receivable"
                            :key="`receivable_${index}`"
                        >
                            {{ item }}
                        </td>
                    </tr>
                    <tr>
                        <td class="tit">
                            累计实收
                        </td>
                        <td
                            class="num"
                            v-for="(item, index) in receipt"
                            :key="`receipt${index}`"
                        >
                            {{ item }}
                        </td>
                    </tr>
                    <tr>
                        <td class="tit">
                            应收账款余额
                        </td>
                        <td
                            class="num"
                            v-for="(item, index) in receivable_balance"
                            :key="`receivable_balance${index}`"
                        >
                            {{ item }}
                        </td>
                    </tr>
                    <tr>
                        <td class="tit">
                            货款回收率
                        </td>
                        <td
                            class="num"
                            v-for="(item, index) in rate"
                            :key="`rate${index}`"
                        >
                            {{ item }}%
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- 图表 -->
            <div class="chartCon">
                <div class="chart" id="rateChart"></div>
                <div class="chart" id="chart"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: Object,
        },
    },
    data() {
        return {
            names: [],
            // 实收
            receipt: [],
            // 应收账款余额
            receivable_balance: [],
            // 应收
            receivable: [],
            // 货款回收率
            rate: [],
            title: '',
            qi_y_id: '',
            // 开始时间
            beginTime: '',
            // 结束时间
            endTime: '',
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        // 初始化
        init() {
            // 应收账款构成chart
            this.title = this.extr.title;
            this.qi_y_id = this.extr.qi_y_id;
            this.handleBalanceamount();
            // 回收率chart
            const date = new Date();
            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            this.beginTime = year + '-01';
            month = month < 10 ? '0' + month : month;
            this.endTime = year + '-' + month;
            this.handleSaledayApi();
        },
        // 处理应收账款构成
        async handleBalanceamount() {
            const res = await this.getBalanceamount();
            if (res) {
                res.map(item => {
                    this.names.push(item.category);
                    this.receipt.push(item.receipt);
                    this.receivable_balance.push(item.receivable_balance);
                    this.receivable.push(item.receivable);
                    if (item.rate) {
                        this.rate.push(item.rate.substr(0, item.rate.length - 1));
                    } else {
                        this.rate.push(0);
                    }
                });
                const data = {
                    xAxis: { data: this.names },
                    receivable_balance: this.receivable_balance,
                    rate: this.rate,
                };
                this.lineColumn('chart', data);
            }
        },
        // 处理货款回收率
        async handleSaledayApi() {
            const res = await this.getSaledayApi();
            if (res) {
                const xAxis = [];
                const loans_receivable = [];
                const official_receipt = [];
                const balance_receivable_rate = [];
                res.map((item, index) => {
                    if (index !== 0) {
                        xAxis.push(item.month);
                        if (item.loans_receivable) {
                            const obj = (item.loans_receivable / 10000).toFixed(2);
                            loans_receivable.push(obj);
                        } else {
                            loans_receivable.push(0);
                        }
                        if (item.official_receipt) {
                            const obj = (item.official_receipt / 10000).toFixed(2);
                            official_receipt.push(obj);
                        } else {
                            official_receipt.push(0);
                        }
                        balance_receivable_rate.push(item.balance_receivable_rate);
                    }
                });
                const data = {
                    xAxis: { data: xAxis },
                    yAxis: [
                        {
                            name: '单位(万元)',
                            nameTextStyle: '',
                        },
                        {
                            name: '单位(%)',
                            nameTextStyle: '',
                        },
                    ],
                    series: [
                        {
                            type: 'bar',
                            name: '本月主收',
                            barWidth: 15,
                            data: loans_receivable,
                            color: ['#23D2FD', '#2D7AE8'],
                        },
                        {
                            type: 'bar',
                            name: '本月实收',
                            barWidth: 15,
                            data: official_receipt,
                            color: ['#AED563', '#78D563'],
                        },
                        {
                            type: 'line',
                            name: '月货款回收率',
                            data: balance_receivable_rate,
                            color: ['#FC9343'],
                        },
                    ],
                };
                this.lineColumn2('rateChart', data);

            }
        },
        // 企业应收账款构成
        getBalanceamount() {
            const path = '/interfaceApi/report/leadercockpit/drill/balanceamountgc/' + this.qi_y_id;
            return this.$axios.$get(path, { defEx: true });
        },
        // 获取数据    
        getSaledayApi() {
            const path = '/interfaceApi/sale/accounts/receivable_month?beginTime=' + this.beginTime + '&endTime=' + this.endTime;
            return this.$axios.$get(path, { defEx: true });
        },
        // 折线柱图
        lineColumn2(obj, data) {
            const lineColumnChart = window.$globalHub.$echarts.init(document.getElementById(obj));
            const option = {
                title: {
                    text: '',
                    x: 'center',
                    y: 0,
                    textStyle: {
                        color: '#B4B4B4',
                        fontSize: 16,
                        fontWeight: 'normal',
                    },
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                        label: {
                            show: true,
                        },
                    },
                },
                legend: {
                    textStyle: {
                        color: '#fff',
                    },
                    top: 0,
                    right: 10,
                },
                grid: {
                    left: 60,
                    top: 50,
                    right: 60,
                    bottom: 40,
                },
                xAxis: [{
                    // type:'category',
                    data: [],
                    boundaryGap: true,
                    axisLine: {
                        show: true,
                        color: 'rgba(255,255,255,0.1)',
                    },
                    splitArea: {
                        color: '#f00',
                        lineStyle: {
                            color: '#f00',
                        },
                    },
                    axisLabel: {
                        color: 'rgba(255,255,255,0.5)',
                        rotate: 30,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                }],
                yAxis: [],
                series: [],
            };
            if (data) {
                if (data.grid) {
                    option.grid = data.grid;
                }

                const yAxis = [];
                const series = [];
                if (data.grid) {
                    option.grid = data.grid;
                }
                option.xAxis[0].data = data.xAxis.data;
                data.yAxis.forEach(item => {
                    if (item.show === 'false') {
                        item.show = false;
                    } else {
                        item.show = true;
                    }
                    yAxis.push({
                        name: item.name,
                        // nameLocation: 'middle',
                        nameTextStyle: {
                            color: 'rgba(255,255,255,0.5)',
                            padding: item.nameTextStyle,
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: 'rgba(255,255,255,0.1)',
                                width: 0.5,
                                type: 'dashed',
                            },
                        },
                        axisLine: {
                            show: true,
                            color: 'rgba(255,255,255,0.1)',
                        },
                        axisLabel: {
                            show: true,
                            color: 'rgba(255,255,255,0.5)',
                        },
                        axisTick: {
                            show: false,
                        },
                        show: item.show,
                    });
                });
                option.yAxis = yAxis;
                data.series.forEach(item => {
                    let barYAxisIndex = 0;
                    let lineYAxisIndex = 1;
                    if (item.type === 'bar') {
                        if (item.yAxisIndex || item.yAxisIndex === 0) {
                            barYAxisIndex = item.yAxisIndex;
                        }
                        series.push({
                            name: item.name,
                            type: 'bar',
                            barWidth: item.barWidth,
                            yAxisIndex: barYAxisIndex,
                            itemStyle: {
                                normal: {
                                    color: new window.$globalHub.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        offset: 0,
                                        color: item.color[0],
                                    },
                                    {
                                        offset: 1,
                                        color: item.color[1],
                                    },
                                    ]),
                                },
                            },
                            data: item.data,
                        });
                    } else if (item.type === 'line') {
                        if (item.yAxisIndex || item.yAxisIndex === 0) {
                            lineYAxisIndex = item.yAxisIndex;
                        }
                        series.push({
                            name: item.name,
                            type: 'line',
                            // smooth: true,
                            showSymbol: true,
                            symbolSize: 8,
                            lineStyle: {
                                normal: {
                                    color: item.color[0],
                                },
                            },
                            yAxisIndex: lineYAxisIndex,
                            label: {
                                show: false,
                                position: 'top',
                                textStyle: {
                                    color: item.color[0],
                                },
                            },
                            itemStyle: {
                                color: '#6c50f3',
                                borderColor: '#fff',
                                borderWidth: 1,
                                shadowColor: 'rgba(0, 0, 0, .3)',
                                shadowBlur: 0,
                                shadowOffsetY: 2,
                                shadowOffsetX: 2,
                                normal: {
                                    color: item.color[0],
                                    lineStyle: {
                                        color: item.color[0],
                                    },
                                },
                            },
                            data: item.data,
                        });
                    }
                });
                option.series = series;
            }
            lineColumnChart.setOption(option);
        },
        // 折线柱图
        lineColumn(obj, data) {
            const lineColumnChart = window.$globalHub.$echarts.init(document.getElementById(obj));
            const option = {
                title: {
                    text: '',
                    x: 'center',
                    y: 0,
                    textStyle: {
                        color: '#B4B4B4',
                        fontSize: 16,
                        fontWeight: 'normal',
                    },
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                        label: {
                            show: true,
                        },
                    },
                },
                legend: {
                    textStyle: {
                        color: '#fff',
                    },
                    top: 0,
                    right: 10,
                },
                grid: {
                    left: 60,
                    top: 50,
                    right: 60,
                    bottom: 40,
                },
                xAxis: [{
                    // type:'category',
                    data: [],
                    boundaryGap: true,
                    axisLine: {
                        show: true,
                        color: 'rgba(255,255,255,0.1)',
                    },
                    splitArea: {
                        color: '#f00',
                        lineStyle: {
                            color: '#f00',
                        },
                    },
                    axisLabel: {
                        color: 'rgba(255,255,255,0.5)',
                    },
                    splitLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                }],
                yAxis: [
                    {
                        name: '单位(万元)',
                        // nameLocation: 'middle',
                        nameTextStyle: {
                            color: 'rgba(255,255,255,0.5)',
                            padding: '',
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: 'rgba(255,255,255,0.1)',
                                width: 0.5,
                                type: 'dashed',
                            },
                        },
                        axisLine: {
                            show: true,
                            color: 'rgba(255,255,255,0.1)',
                        },
                        axisLabel: {
                            show: true,
                            color: 'rgba(255,255,255,0.5)',
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                    {
                        name: '单位(%)',
                        // nameLocation: 'middle',
                        nameTextStyle: {
                            color: 'rgba(255,255,255,0.5)',
                            padding: '',
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: 'rgba(255,255,255,0.1)',
                                width: 0.5,
                                type: 'dashed',
                            },
                        },
                        axisLine: {
                            show: true,
                            color: 'rgba(255,255,255,0.1)',
                        },
                        axisLabel: {
                            show: true,
                            color: 'rgba(255,255,255,0.5)',
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: '应收账款余额',
                        type: 'bar',
                        barWidth: 15,
                        yAxisIndex: 0,
                        stack: '累计应收',
                        itemStyle: {
                            normal: {
                                color: new window.$globalHub.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#24CCFC',
                                },
                                {
                                    offset: 1,
                                    color: '#2F80F2',
                                },
                                ]),
                            },
                        },
                        data: data.receivable_balance,
                    },
                    {
                        name: '货款回收率',
                        type: 'line',
                        // smooth: true,
                        showSymbol: true,
                        symbolSize: 8,
                        lineStyle: {
                            normal: {
                                color: '#FC9343',
                            },
                        },
                        yAxisIndex: 1,
                        label: {
                            show: false,
                            position: 'top',
                            textStyle: {
                                color: '#FC9343',
                            },
                        },
                        itemStyle: {
                            color: '#6c50f3',
                            borderColor: '#fff',
                            borderWidth: 1,
                            shadowColor: 'rgba(0, 0, 0, .3)',
                            shadowBlur: 0,
                            shadowOffsetY: 2,
                            shadowOffsetX: 2,
                            normal: {
                                color: '#FC9343',
                                lineStyle: {
                                    color: '#FC9343',
                                },
                            },
                        },
                        data: data.rate,
                    },
                ],
            };
            option.xAxis[0].data = data.xAxis.data;
            lineColumnChart.setOption(option);
        },
    },
};
</script>
<style lang="stylus">
@import './enterDrill.styl'
</style>